// Variables
$primaryColor: #413120;
$secondaryColor: #ca9e67;
$otherColor: #cbbba0;
$whiteColor: #ffffff;
$blackColor: #333333;

// Responsive Mixin
@mixin responsive-size($size) {
  @if $size == Ldevice {
    @media (max-width: 1199px) {
      @content;
    }
  }
  @if $size == Ltab {
    @media (max-width: 992px) {
      @content;
    }
  }
  @if $size == tab {
    @media (max-width: 769px) {
      @content;
    }
  }
  @if $size == mobile {
    @media (max-width: 577px) {
      @content;
    }
  }
}

// Global Classes
.section_spacing {
  padding: 50px 0;
  @include responsive-size(mobile) {
    padding: 30px 0;
  }
}
.section_spacing_small {
  padding: 20px 0;
}
.left_padding_0 {
  padding-left: 0;
  @include responsive-size(tab) {
    padding-left: 15px;
  }
}

.mob__order__1 {
  @include responsive-size(tab) {
    order: 1;
  }
  @include responsive-size(mobile) {
    order: 1;
  }
}
.mob__order__2 {
  @include responsive-size(tab) {
    order: 2;
  }
  @include responsive-size(mobile) {
    order: 2;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  color: #333333;
}

// Product Style
// Product Details
.product_details {
  .product_img {
    img {
      width: 100%;
    }
  }

  .product_list_info {
    .list_title h4 {
      font-family: Nunito;
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 30px;
      @include responsive-size(tab) {
        margin: 20px 0;
      }
    }

    .list_items {
      min-width: 360px;
      @include responsive-size(Ltab) {
        min-width: 260px;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        &:last-child {
          border-bottom: 0;
        }

        .item_title,
        .item_info {
          font-family: Nunito;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 0;
        }
        .item_title {
          max-width: 50%;
          flex-basis: 50%;

          color: rgba(0, 0, 0, 0.5);
        }
        .item_info {
          max-width: 50%;
          flex-basis: 50%;

          color: #000000;
        }
      }
    }
  }

  .product_desc {
    .desc_title {
      margin: 30px 0;
      h6 {
        background: #413120;
        width: 200px;
        padding: 10px;
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        margin-bottom: 0;
        text-align: center;
      }
    }
    .desc_content {
      p {
        font-family: Nunito;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        color: #333333;
      }
    }
  }
}

// Services
.services_list {
  .simple__card {
    .centerinfo {
      width: 398px;
      @include responsive-size(Ltab) {
        width: 240px;
        h4 {
          padding: 0 10px;
        }
      }
      @include responsive-size(mobile) {
        width: 270px;
      }
    }
  }
}
// Services List
.smallevenodd_items {
  margin-bottom: 40px;
  .oddeven__card {
    margin-bottom: 20px;
    .img__container {
      height: 250px;
      border-radius: 10px;
    }
    .title {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      text-transform: capitalize;
      color: #413120;
      margin-bottom: 10px;
    }
  }

  .odd__card {
    .info__container {
      padding-left: 25px;
      @include responsive-size(tab) {
        padding-left: 0;
      }
    }
  }

  .even__card {
    .info__container {
      padding-right: 25px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      @include responsive-size(tab) {
        align-items: flex-start;
        text-align: left;
        padding-right: 0;
      }
    }
  }
}

// Service delivery
//
.services_delivery {
  .delivery_list {
    margin-top: 40px;
  }
}

// Factory & Stockyard
//
.factory_stockyard {
  .simple__card .card__link {
    position: relative;
    display: block;
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .centerinfo {
      z-index: 15;
    }
  }
}

// Our Project
//
.our_project {
  .section_spacing {
    padding-top: 30px;
  }
}

// Our Project Details
.our_project_details {
  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
  }
  .zoom__card {
    margin-bottom: 20px;
  }
  .project_description {
    text-align: center;
    margin-bottom: 50px;
    h3 {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 41px;
      color: #333333;
      margin-bottom: 10px;
    }
    p {
      font-family: Nunito;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 200%;
      color: #333333;
    }
  }
  .detail_info {
    background: #ffffff;
    border: 1px solid rgba(51, 51, 51, 0.2);
    border-radius: 5px;
    padding: 30px 20px;
    margin-bottom: 50px;
    h4 {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      color: #333333;
      margin-bottom: 20px;
      text-align: center;
    }
    .info_list_wrap {
      .info_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        &:last-child {
          padding-bottom: 0;
        }
        p {
          margin-bottom: 0;
          line-height: 26px;
        }
      }
    }
  }
  .social_share {
    margin-top: 20px;
    text-align: center;
    a {
      text-decoration: none;
      margin: 0 5px;
    }
  }
}

// About US Page
// media & News
.content {
  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    color: #333333;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }
  .text_content {
    .category {
      background: #ca9e67;
      border-radius: 100px;
      padding: 5px 30px;
      display: inline-block;
      margin-bottom: 15px;
      p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
      }
    }
    .title {
      margin-bottom: 15px;
      h2 {
        font-size: 36px;
        line-height: 47px;
        @include responsive-size(mobile) {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
    .subtitle {
      margin-bottom: 15px;
      h3 {
        font-size: 30px;
        line-height: 39px;
        color: rgba(51, 51, 51, 0.7);
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 105px;
          height: 1px;
          background: rgba(0, 0, 0, 0.3);
          top: 50%;
          transform: translateY(-50%);
          margin-left: 20px;
        }
        @include responsive-size(mobile) {
          font-size: 24px;
        }
      }
    }
  }

  .content_img {
    @include responsive-size(tab) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      max-height: 450px;
      border-radius: 10px;
      @media screen and(max-width: 450px) {
        max-height: 300px;
      }
    }
  }
  // Media & News Details
  .content_banner {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
  .content_detail {
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .bold_text {
    font-size: 24px;
    line-height: 33px;
    color: #333333;
  }
  .quote_mark {
    font-size: 24px;
    line-height: 33px;
    color: #ca9e67;

    display: flex;
    align-items: flex-start;

    img {
      width: 60px;
      margin-right: 15px;
      @include responsive-size(tab) {
        width: 40px;
      }
    }
  }

  .page_name,
  .large_title {
    font-size: 24px;
    line-height: 36px;
    color: #333333;
  }
  .page_subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.216667px;
    color: #333333;
  }
}

// Contact US
.coutact_us {
}

.contact_info {
  position: relative;
  @include responsive-size(tab) {
    height: 450px;
    margin-bottom: 40px;
  }
  .info_bgImg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 420px;
    height: 440px;
    @include responsive-size(Ldevice) {
      width: 350px;
    }
    @include responsive-size(Ldevice) {
      width: 320px;
      height: 470px;
    }
    @include responsive-size(tab) {
      width: 100%;
      height: 100%;
    }
    @include responsive-size(mobile) {
      left: -15px;
      top: -15px;
    }
  }
  .info_bgimgTop {
    position: absolute;
    top: 80px;
    left: 120px;
    z-index: 5;
    width: 420px;
    height: 440px;
    @include responsive-size(Ldevice) {
      left: 30px;
      top: 30px;
      width: 420px;
    }
    @include responsive-size(Ltab) {
      left: 20px;
      top: 20px;
      width: 320px;
      height: 470px;
    }
    @include responsive-size(tab) {
      width: 100%;
      height: 100%;
    }
    @include responsive-size(tab) {
      left: 0;
      top: 0;
      height: 470px;
    }
  }
  .address {
    position: absolute;
    left: 120px;
    top: 80px;
    z-index: 7;
    max-width: 500px;
    padding: 30px;
    @include responsive-size(Ldevice) {
      left: 30px;
      top: 30px;
      max-width: 400px;
    }
    h2 {
      font-size: 36px;
      line-height: 49px;
      color: #ffffff;
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 30px;
      &::after {
        position: absolute;
        content: "";
        width: 127px;
        height: 2px;
        background: #ca9e67;
        left: 0;
        bottom: 0;
      }
    }
    .detail_address {
      color: #ffffff;
      margin-bottom: 40px;
    }
    .contact_no,
    .internet_info {
      margin-bottom: 40px;
      p {
        color: #fff;
        margin-bottom: 5px;
        span {
          width: 50px;
          display: inline-block;
        }
      }
    }
    .internet_info {
      margin-bottom: 20px;
    }

    .social_address {
      a {
        margin-right: 25px;
        text-decoration: none;
      }
    }
  }
}
.get_intouch {
  h2 {
    font-size: 36px;
    line-height: 49px;
    color: #ca9e67;
    margin-bottom: 10px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
    margin-bottom: 30px;
  }
  .form-group {
    margin-bottom: 30px;
  }
}

.google_map_container {
  position: static;
  margin-top: 30px;
  img {
    width: 100%;
  }
}

//
.product_img {
  padding-bottom: 100px;
  .slick-thumb {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    // margin-bottom: 20px;
    bottom: auto;
    li {
      width: 18%;
      margin: 2;
      @include responsive-size(mobile) {
        width: 18%;
      }
    }
  }
}
