//Variables
$primaryColor: #ca9e67;
$secondaryColor: #333333;
$minBannerColor: #f7f9fb;

.navbar {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 0px 100px;
}

.dropdown {
  position: unset;

  &:hover {
    // &::after {
    //   content: "";
    //   border-bottom: 2px solid $primaryColor;
    //   position: absolute;
    //   width: 100%;
    //   bottom: 0;
    //   left: 0;
    // }
    .custom-nav-link {
      color: $primaryColor;
      cursor: pointer;
      position: relative;

      &::before {
        content: url("/images/arrow_drop_down.svg");
        position: absolute;
        bottom: -5px;
        left: 48%;
      }
    }

    .mega-menu {
      display: block;
      min-width: 100%;
      top: 96%;
      border-radius: 0px 0px 5px 5px;
      background: #f3f3f3;
    }

    .custom-dropdown-menu {
      display: block;
      // min-width: 100%;
      top: 96%;
      border-radius: 0px 0px 5px 5px;
      background: #f3f3f3;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.custom-nav-item.show {
  .mega-menu {
    display: block;
    min-width: 100%;
    top: 96%;
    border-radius: 0px 0px 5px 5px;
    background: #f3f3f3;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .custom-dropdown-menu {
    display: block;
    // min-width: 100%;
    top: 96%;
    border-radius: 0px 0px 5px 5px;
    background: #f3f3f3;
  }
}

.custom_navbar {
  .custom-navbar-collapse {
    .custom-navbar-nav {
      .custom-nav-item {
        cursor: pointer;

        .mega-menu {
          padding-top: 30px;
          padding-bottom: 30px;
          border-top: 2px solid $primaryColor;

          .sub-menu {
            .submenu__options {
              .sub-title {
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 27px;
                align-items: center;
                color: $secondaryColor;
              }

              .list-unstyled {
                li {
                  padding-top: 10px;
                  padding-bottom: 10px;

                  a {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 22px;
                    align-items: center;
                    color: $secondaryColor;
                    text-decoration: none;
                  }
                }

                li:not(:last-child) {
                  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                }
              }
            }
          }

          .sub-menu:not(:last-child) {
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            margin-right: 20px;
          }
        }

        .custom-dropdown-menu {
          border-top: 2px solid $primaryColor;
          padding: 15px 20px;
          width: 250px;

          .experience__center__options {
            .list-unstyled {
              li {
                padding: 10px 0px;

                a {
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 27px;
                  align-items: center;
                  color: $secondaryColor;
                  text-decoration: none;
                }
              }

              li:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              }
            }
          }
        }

        .dropdown-toggle::after {
          display: none;
        }

        .custom-nav-link {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          padding-top: 25px;
          padding-bottom: 25px;
          color: #333333;
        }
      }

      .custom-nav-item:not(:last-child) {
        margin-right: 15px;
      }

      .mini-dropdown {
        position: relative;
      }
    }
  }
}

.mobile_menu_option {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .mobile_menu_option {
    display: none;
  }
}

@media (max-width: 991px) {
  .custom-navbar-collapse {
    display: none;
  }
}

@media (max-width: 1260px) {
  .custom_navbar {
    padding: 0px 60px;

    .custom-navbar-collapse {
      .custom-navbar-nav {
        .custom-nav-item:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .custom_navbar {
    padding: 0px 40px;

    .custom-navbar-collapse {
      .custom-navbar-nav {
        .custom-nav-item {
          .custom-nav-link {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
          }
        }

        .custom-nav-item:not(:last-child) {
          margin-right: 2px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .custom_navbar {
    padding: 10px 40px;
  }
}

@media (max-width: 575px) {
  .custom_navbar {
    padding: 10px 15px;
  }
}

///////Mini Banner Section Css ///////
.mini__banner__section {
  background: $minBannerColor;
  padding-top: 33px;
  padding-bottom: 20px;

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    align-items: center;
    text-align: center;
    color: $secondaryColor;
    margin-bottom: 15px;
  }

  .listing__content {
    text-align: center;

    li {
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        color: $secondaryColor;
        text-decoration: none;
      }
    }

    li:not(:last-child) {
      margin-right: 15px;
    }

    .active {
      a {
        color: $primaryColor;
      }
    }
  }
}

/////Faq Page Css /////
.frequestlyAsk__questions__section {
  margin: 80px 0px;

  .faq-title {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 49px;
    align-items: center;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 10px;
  }

  .faq-sub {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: $secondaryColor;
    padding: 0px 150px;
  }

  .collapse-questions-section {
    padding-top: 30px;

    .css-collapse__content {
      .tab:not(:last-child) {
        margin-bottom: 10px;
      }

      .tab {
        position: relative;
        margin-bottom: 1px;
        width: 100%;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid rgba(42, 60, 80, 0.15);
        box-sizing: border-box;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 3px;

        &:last-child input:checked ~ label {
          border-bottom: none;
        }

        input {
          position: absolute;
          opacity: 0;
          z-index: -1;
        }

        label {
          font-size: 18px;
          margin: 0;
          line-height: 25px;
          font-weight: normal;
          color: #2a3c50;
          position: relative;
          display: block;
          padding: 10px;
          cursor: pointer;
          padding-left: 20px;
          transition: 0.15s;
          padding-right: 35px;
        }

        .tab-content {
          max-height: 0;
          font-size: 16px;
          line-height: 22px;
          font-weight: 300;
          letter-spacing: 0.216667px;
          padding-left: 20px;
          padding-right: 50px;
          overflow: hidden;
          -webkit-transition: max-height 0.05s;
          -o-transition: max-height 0.05s;
          transition: max-height 0.05s;
          color: rgba(42, 60, 80, 0.8);
          background: transparent;
        }
      }

      .tab input:checked ~ .tab-content {
        max-height: 100%;
        margin-bottom: 15px;
      }

      .tab label::before {
        position: absolute;
        right: 15px;
        top: 8px;
        display: block;
        text-align: center;
      }

      .tab input[type="checkbox"] + label::before {
        content: "+";
        font-size: 25px;
        color: #2a3c50;
      }

      .tab input[type="checkbox"]:checked + label::before {
        //transform: rotate(315deg);
        content: "-";
        font-size: 35px;
        color: #2a3c50;
        top: 6px;
      }
    }
  }
}

@media (max-width: 991px) {
  .frequestlyAsk__questions__section .faq-sub {
    padding: 0px;
  }
}

@media (max-width: 575px) {
  .frequestlyAsk__questions__section {
    margin-top: 30px;

    .faq-title {
      font-size: 24px;
      line-height: 33px;
    }

    .faq-sub {
      font-size: 16px;
      line-height: 24px;
    }

    .collapse-questions-section .css-collapse__content .tab label {
      font-size: 15px;
      line-height: 22px;
    }
  }
}

.stillHave__questions__section {
  padding: 80px 0px;
  background: #f7f9fb;

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 49px;
    align-items: center;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 10px;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: $secondaryColor;
    padding: 0px 230px;
  }

  .questions__form__section {
    form {
      .custom_form-row {
        justify-content: center;

        .custom_form-group {
          input {
            height: calc(2em + 0.75rem + 2px);
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          textarea {
            resize: none;
          }

          .custom_form-control {
            background: #ffffff;
            border: 1px solid #ececec;
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
          }
        }

        .custom__submit__btn {
          background: $primaryColor;
          border: 1px solid $primaryColor;
          box-sizing: border-box;
          border-radius: 2px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .stillHave__questions__section p {
    padding: 0px;
  }
}

@media (max-width: 575px) {
  .stillHave__questions__section {
    padding-top: 30px;

    h2 {
      font-size: 24px;
      line-height: 33px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

////Store Locator Css /////
.store__location__section {
  margin-top: 30px;
  margin-bottom: 50px;

  .location-options {
    background: #ffffff;
    border: 1px solid rgba(42, 60, 80, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;

    .area__formgroup {
      display: none;
    }

    .map__img {
      width: 100%;
    }

    .District-list-container {
      .vertical-menu {
        width: inherit;
        height: 425px;
        overflow-y: auto;

        // padding-left: 30px;
        // margin-top: 100px;
        ul {
          a {
            text-decoration: none;
            margin-right: 10px;
            display: block;
          }

          .custom-list-group-item {
            border: 1px solid rgba(0, 0, 0, 0.125);
          }
        }

        .list-group-item.active {
          background: #ffffff;
          border-color: $primaryColor;
        }

        .custom-list-group-item {
          margin-bottom: 25px;
          margin-right: 10px;

          .active {
            border-color: $primaryColor;
          }
        }

        .store-locatore-option {
          padding-top: 2px;

          h5 {
            font-size: 14px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 0px;
          }

          .store-locatore-option-details {
            padding-top: 8px;
            padding-bottom: 12px;

            .custom-view {
              padding-left: 18px;
              padding-right: 0px;
            }

            .custom-description {
              padding-left: 0px;

              p {
                color: rgba(1, 1, 1, 0.54);
                font-size: 12px;
                line-height: 18px;
                text-align: left;
                margin-right: 12px;
                padding-top: 2px;

                span {
                  font-weight: 600;
                  color: #2a3c50;
                }
              }
            }
          }
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: $primaryColor;
        border-radius: 20px;
      }

      ::-webkit-scrollbar-track {
        background: #e8e8e8;
      }
    }

    @media (max-width: 420px) {
      background-color: transparent;

      .District-list-container {
        h4 {
          padding-left: 0px;
        }

        .vertical-menu {
          width: auto;
          padding-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .store__location__section {
    .location-options {
      border: none;
      padding: 0px;

      .area__formgroup {
        display: block;
      }

      .District-list-container {
        display: none;
      }
    }
  }
}

///////Simulator Css All//////
.visual_start_now_section {
  background: url("/images/simulator__bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;

  .btn1__margin {
    margin-top: 10px;
  }
  //.bottom-triangle::after{
  //  content: '';
  //  display: inline-block;
  //  position: relative;
  //  border-style: none;
  //  top: -1px;
  //  right: -52px;
  //  bottom: -16px;
  //  height: 1px;
  //  background-color:transparent;
  //  border-top: 10px solid white;
  //  border-left: 10px solid #ca9e67;
  //  border-right: 10px solid #ca9e67;
  //  //border-bottom: 10px solid red;
  //
  //}
  .marginclass {
    margin-bottom: 1000px;
  }
  .triangle-left {
    top: 53px;
    left: 68px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ca9e67;
    position: relative;
    transform: rotate(-90deg);
  }

  .triangle-left:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-right: 21px solid white;
    position: absolute;
    top: -21px;
    left: 1px;
  }
  .top__nav {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 5px;

    .simulator__part {
      .btn-group-lg {
        button {
          display: grid;
          font-weight: normal;
          font-size: 14px;
          line-height: 15px;
          text-align: center;
          color: #000000;
          background: #ffffff;
          border: 1px solid #ca9e67;
          // box-sizing: border-box;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 4px 23px;
          margin-right: 15px;
          align-content: center;
          width: 120px;
          height: 60px;
        }
      }
    }
  }

  .center_description {
    position: absolute;
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 45%;
    text-align: center;

    .t__text {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      text-transform: uppercase;
      color: #ffffff;

      span {
        font-weight: normal;
        font-size: 30px;
        line-height: 33px;
      }
    }

    .b__text {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      text-transform: uppercase;
      color: #ffffff;

      span {
        color: #ffffff;
        font-size: 30px;
        font-weight: normal;
      }
    }

    .get__start__btn {
      border: 1px solid #ffffff;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      border-radius: 0px;
      padding: 12px 21px;
    }
  }

  .all__spec__selectBtn {
    .btn__one {
      position: absolute;
      top: 50%;
      left: 20px;
    }

    .btn__two {
      position: absolute;
      top: 25%;
      left: 50%;
    }

    .btn__three {
      position: absolute;
      top: 39%;
      right: 20px;
    }

    .btn__four {
      position: absolute;
      bottom: 20px;
      left: 50%;
    }

    .btn__five {
      position: absolute;
      bottom: 10px;
      right: 5%;
      button {
        display: grid;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        background: #ffffff;
        border: 1px solid #ca9e67;
        // box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 4px 20px;
        margin-right: 15px;
        align-content: center;
        width: 120px;
        height: 60px;
      }
    }
  }

  .wall {
    background-color: white;
    width: 100%;
    height: 72%;
    position: absolute;
    top: 0;
    z-index: -1;
    // background-image: url("https://html.tilesvisualizer.com/app/images/tile_353.jpg");
    background-repeat-x: repeat;
    background-repeat-y: repeat;
    background-size: 15%;
  }

  .floor {
    // background-color: blue;
    width: 100%;
    height: 28%;
    position: absolute;
    bottom: 0;
    z-index: 1;

    overflow: hidden;

    perspective: 150px;
    // transform: translateZ(0px) rotateX(50deg);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;

    ul {
      width: 150%;
      list-style: none;

      transform: translateZ(0px) rotateX(48deg);
      transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;

      li {
        display: block;
        float: left;
        // border-right: 0.5px solid #666;
        // border-bottom: 0.5px solid #666;

        -webkit-backface-visibility: hidden;
        transform-style: preserve-3d;
        pointer-events: none;
        // background-color: #303030;
        // background: url(https://html.tilesvisualizer.com/app/images/tile_394.jpg) left top / 100% 100% no-repeat;
        width: 120px;
        height: 80px;
      }
    }
  }
}

@media (max-width: 850px) {
  .visual_start_now_section {
    .center_description {
      .t__text {
        font-size: 36px;
        line-height: 40px;
      }

      .b__text {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 850px) and (max-height: 500px) {
  .visual_start_now_section {
    height: 86vh;

    .top__nav {
      padding-left: 5px;

      .simulator__part {
        .btn-group-lg {
          button {
            width: 70px;
            height: 30px;
            padding: 3px 10px;
            font-size: 8px;
            line-height: 9px;

            svg {
              height: 25px;
              width: 25px;
              margin: 0 auto;
              margin-bottom: 3px;
            }
          }
        }
      }
      .triangle-left {
        top: 27px;
        left: 37px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #ca9e67;
        transform: rotate(-90deg);
      }

      .triangle-left:after {
        content: "";
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid white;
        position: absolute;
        top: -8px;
        left: 2px;
      }
    }

    .center_description {
      top: 35%;
    }
  }
}

////////////All Modal Css //////////
@mixin grid($cols, $mgn) {
  float: left;
  margin-right: $mgn;
  margin-bottom: 20px;
  width: ((100% - (($cols - 1) * $mgn)) / $cols);

  &:nth-child(#{$cols}n) {
    margin-right: 0;
  }
}

/////Select Product Modal////////
.global__simulator_modal {
  .custom_simulator_modal-dialog {
    .custom_modal-content {
      .custom_modal-header {
        background: $primaryColor;
        display: block;
        padding: 0px;
        position: relative;

        .custom_modal-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          align-items: center;
          text-align: center;
          color: #ffffff;
          padding: 15px 0px;
        }

        .custom__close {
          color: $minBannerColor;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }

      .custom_modal-body {
        .product__selection__part {
          .custom__nav-pills {
            justify-content: center;

            .custom_nav-item {
              border: 1px solid rgba(51, 51, 51, 0.2);
              box-sizing: border-box;
              border-radius: 2px;
              width: 100px;

              .custom_nav-link {
                text-decoration: none;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: $secondaryColor;
              }
            }

            .custom_nav-item:not(:last-child) {
              margin-right: 10px;
            }

            .custom_nav-item {
              .custom_nav-link.active {
                color: $minBannerColor;
                background: $secondaryColor;
                border-radius: 0px;
              }
            }
          }

          .custom_tab-content {
            .custom_tab-pane {
              padding: 0px;

              .custom_nav-tabs {
                justify-content: center;

                .custom_nav-item {
                  .custom_nav-link {
                    border: none;
                    font-size: 16px;
                    line-height: 32px;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.216667px;
                    color: $secondaryColor;
                  }

                  .active {
                    border-bottom: 2px solid $primaryColor;
                    color: $primaryColor;
                    font-weight: 600;
                  }
                }
              }

              .inner__tab-content {
                .inner__tab-pane {
                  .global__content {
                    ::-webkit-scrollbar {
                      width: 5px;
                    }

                    ::-webkit-scrollbar-thumb {
                      background: $primaryColor;
                      border-radius: 20px;
                    }

                    ::-webkit-scrollbar-track {
                      background: #e8e8e8;
                    }

                    .visual__simulator__options {
                      list-style: none;
                      padding-left: 0px;
                      height: 615px;
                      overflow-y: auto;
                      padding-right: 10px;
                      margin-top: 15px;

                      li {
                        @include grid(5, 1%);

                        a {
                          text-decoration: none;

                          .card {
                            .card-img-top {
                              width: 100%;
                              padding: 10px 10px 0px 10px;
                            }

                            .desc {
                              margin-top: 5px;
                              margin-bottom: 5px;

                              h6 {
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 18px;
                                align-items: center;
                                text-align: center;
                                letter-spacing: 0.216667px;
                                color: #2a3c50;
                                margin-bottom: 5px;
                              }

                              p {
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 18px;
                                align-items: center;
                                text-align: center;
                                letter-spacing: 0.216667px;
                                color: #2a3c50;
                                margin-bottom: 0px;
                              }
                            }
                          }
                        }
                      }

                      // li:nth-child(5n) {
                      //   margin-right: 0;
                      // }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//////Select Room Modal//////
.global__simulator_room_modal {
  .custom_simulator_modal-dialog {
    .custom_modal-content {
      .custom_modal-header {
        background: $primaryColor;
        display: block;
        padding: 0px;
        position: relative;

        .custom_modal-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          align-items: center;
          text-align: center;
          color: #ffffff;
          padding: 15px 0px;
        }

        .custom__close {
          color: $minBannerColor;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }

      .custom_modal-body {
        .product__selection__part {
          .custom__nav-pills {
            justify-content: center;

            .custom_nav-item {
              border: 1px solid rgba(51, 51, 51, 0.2);
              box-sizing: border-box;
              border-radius: 2px;
              width: 100px;

              .custom_nav-link {
                text-decoration: none;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: $secondaryColor;
              }
            }

            .custom_nav-item:not(:last-child) {
              margin-right: 10px;
            }

            .custom_nav-item {
              .custom_nav-link.active {
                color: $minBannerColor;
                background: $secondaryColor;
                border-radius: 0px;
              }
            }
          }

          .custom_tab-content {
            .custom_tab-pane {
              padding: 0px;

              .custom_nav-tabs {
                justify-content: center;

                .custom_nav-item {
                  .custom_nav-link {
                    border: none;
                    font-size: 16px;
                    line-height: 32px;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.216667px;
                    color: $secondaryColor;
                    background: none;
                    outline: none;
                  }

                  .active {
                    border-bottom: 2px solid $primaryColor;
                    color: $primaryColor;
                    font-weight: 600;
                  }
                }
              }

              .inner__tab-content {
                .inner__tab-pane {
                  .global__content {
                    ::-webkit-scrollbar {
                      width: 5px;
                    }

                    ::-webkit-scrollbar-thumb {
                      background: $primaryColor;
                      border-radius: 20px;
                    }

                    ::-webkit-scrollbar-track {
                      background: #e8e8e8;
                    }

                    .visual__simulator__options {
                      list-style: none;
                      padding-left: 0px;
                      height: 510px;
                      overflow-y: auto;
                      padding-right: 10px;
                      margin-top: 15px;

                      li {
                        @include grid(5, 1%);

                        a {
                          text-decoration: none;

                          .card {
                            border: none;

                            .card-img-top {
                              width: 100%;
                            }

                            .desc {
                              margin-top: 5px;
                              margin-bottom: 5px;

                              h6 {
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 18px;
                                align-items: center;
                                text-align: center;
                                letter-spacing: 0.216667px;
                                color: #2a3c50;
                                margin-bottom: 5px;
                              }

                              p {
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 18px;
                                align-items: center;
                                text-align: center;
                                letter-spacing: 0.216667px;
                                color: #2a3c50;
                                margin-bottom: 0px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//////Select Color Modal//////
.global__simulator_colors_modal {
  .custom_simulator_modal-dialog {
    .custom_modal-content {
      .custom_modal-header {
        background: $primaryColor;
        display: block;
        padding: 0px;
        position: relative;

        .custom_modal-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          align-items: center;
          text-align: center;
          color: #ffffff;
          padding: 15px 0px;
        }

        .custom__close {
          color: $minBannerColor;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }

      .custom_modal-body {
        .product__selection__part {
          .custom__nav-pills {
            justify-content: center;

            .custom_nav-item {
              border: 1px solid rgba(51, 51, 51, 0.2);
              box-sizing: border-box;
              border-radius: 2px;
              width: 100px;

              .custom_nav-link {
                text-decoration: none;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: $secondaryColor;
              }
            }

            .custom_nav-item:not(:last-child) {
              margin-right: 10px;
            }

            .custom_nav-item {
              .custom_nav-link.active {
                color: $minBannerColor;
                background: $secondaryColor;
                border-radius: 0px;
              }
            }
          }

          .custom_tab-content {
            .custom_tab-pane {
              padding: 0px;

              .custom_nav-tabs {
                justify-content: center;

                .custom_nav-item {
                  .custom_nav-link {
                    border: none;
                    font-size: 16px;
                    line-height: 32px;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.216667px;
                    color: $secondaryColor;
                  }

                  .active {
                    border-bottom: 2px solid $primaryColor;
                    color: $primaryColor;
                    font-weight: 600;
                  }
                }
              }

              .inner__tab-content {
                .inner__tab-pane {
                  .global__content {
                    .visual__simulator__options {
                      list-style: none;
                      padding-left: 0px;
                      // padding-right: 10px;
                      margin-top: 15px;

                      li {
                        @include grid(5, 1%);

                        a {
                          text-decoration: none;

                          .card {
                            background: #ab7f5b;
                            border-radius: 2px;
                            width: 100%;
                            height: 75px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

////Product Information Modal//////
.product_info_modal {
  .custom_simulator_modal-dialog {
    .custom_modal-content {
      .custom_modal-header {
        background: $primaryColor;
        display: block;
        padding: 0px;
        position: relative;

        .custom_modal-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          align-items: center;
          text-align: center;
          color: #ffffff;
          padding: 15px 0px;
        }

        .custom__close {
          color: $minBannerColor;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }

      .custom_modal-body {
        .product__selection__part {
          .custom_nav-tabs {
            justify-content: center;
            margin-bottom: 10px;

            .custom_nav-item {
              .custom_nav-link {
                border: none;
                font-size: 16px;
                line-height: 32px;
                align-items: center;
                text-align: center;
                letter-spacing: 0.216667px;
                border-bottom: 1px solid $primaryColor;
                color: $primaryColor;
                font-weight: 600;
              }
            }
          }

          .custom__card {
            border: none;

            .card-img-top {
              width: 100%;
            }

            .custom_card-body {
              padding-left: 0px;
              padding-right: 0px;

              p {
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.216667px;
                color: $secondaryColor;
                margin-bottom: 10px;

                .tag {
                  font-weight: 600;
                  margin-right: 10px;
                }

                .dot {
                  height: 20px;
                  width: 20px;
                  border: 1px solid #dedede;
                  border-radius: 50%;
                  display: inline-block;
                  position: relative;
                  margin-right: 5px;
                }
              }

              .color__descriptn {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .global__simulator_modal {
    .modal-lg {
      max-width: 1150px;
    }
  }

  .global__simulator_room_modal {
    .modal-lg {
      max-width: 1150px;
    }
  }

  .global__simulator_colors_modal {
    .modal-lg {
      max-width: 1150px;
    }
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .global__simulator_modal {
    .modal-lg {
      max-width: 980px;
    }
  }

  .global__simulator_room_modal {
    .modal-lg {
      max-width: 980px;
    }
  }

  .global__simulator_colors_modal {
    .modal-lg {
      max-width: 980px;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .global__simulator_modal {
    .modal-lg {
      max-width: 755px;
    }
  }

  .global__simulator_room_modal {
    .modal-lg {
      max-width: 755px;
    }
  }

  .global__simulator_colors_modal {
    .modal-lg {
      max-width: 755px;
    }
  }
}

@media (max-width: 1199px) {
  .global__simulator_modal
    .custom_simulator_modal-dialog
    .custom_modal-content
    .custom_modal-body
    .product__selection__part
    .custom_tab-content
    .custom_tab-pane
    .inner__tab-content
    .inner__tab-pane
    .global__content
    .visual__simulator__options {
    height: 550px;
  }

  .global__simulator_room_modal
    .custom_simulator_modal-dialog
    .custom_modal-content
    .custom_modal-body
    .product__selection__part
    .custom_tab-content
    .custom_tab-pane
    .inner__tab-content
    .inner__tab-pane
    .global__content
    .visual__simulator__options {
    height: 550px;
  }
}

@media (max-width: 1199px) and (max-height: 825px) {
  .global__simulator_modal
    .custom_simulator_modal-dialog
    .custom_modal-content
    .custom_modal-body
    .product__selection__part
    .custom_tab-content
    .custom_tab-pane
    .inner__tab-content
    .inner__tab-pane
    .global__content
    .visual__simulator__options {
    height: 285px;
  }

  .global__simulator_room_modal
    .custom_simulator_modal-dialog
    .custom_modal-content
    .custom_modal-body
    .product__selection__part
    .custom_tab-content
    .custom_tab-pane
    .inner__tab-content
    .inner__tab-pane
    .global__content
    .visual__simulator__options {
    height: 285px;
  }
}

@media (max-width: 850px) and (max-height: 500px) {
  .global__simulator_modal {
    .custom_simulator_modal-dialog {
      .custom_modal-content {
        .custom_modal-header {
          .custom_modal-title {
            font-size: 16px;
            line-height: 22px;
            padding: 10px 0px;
          }

          .custom__close {
            top: 5px;
          }
        }

        .custom_modal-body {
          padding-top: 10px;

          .product__selection__part {
            .custom__nav-pills {
              .custom_nav-item {
                .custom_nav-link {
                  font-size: 14px;
                  line-height: 15px;
                }
              }
            }

            .custom_tab-content {
              .custom_tab-pane {
                .custom_nav-tabs {
                  margin-top: 10px;

                  .custom_nav-item {
                    .custom_nav-link {
                      font-size: 14px;
                      line-height: 15px;
                    }
                  }
                }

                .inner__tab-content {
                  .inner__tab-pane {
                    .global__content {
                      .visual__simulator__options {
                        height: 150px;

                        li {
                          a {
                            .card {
                              .card-img-top {
                                height: 100px;
                                padding: 5px 5px 0px 5px;
                              }

                              .desc {
                                margin-top: 2px;
                                margin-bottom: 2px;

                                h6 {
                                  font-size: 12px;
                                  line-height: 14px;
                                  margin-bottom: 0px;
                                  font-weight: 600;
                                }

                                p {
                                  font-size: 10px;
                                  line-height: 14px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .global__simulator_room_modal {
    .custom_simulator_modal-dialog {
      .custom_modal-content {
        .custom_modal-header {
          .custom_modal-title {
            font-size: 16px;
            line-height: 22px;
            padding: 10px 0px;
          }

          .custom__close {
            top: 5px;
          }
        }

        .custom_modal-body {
          padding-top: 10px;

          .product__selection__part {
            .custom__nav-pills {
              .custom_nav-item {
                .custom_nav-link {
                  font-size: 14px;
                  line-height: 15px;
                }
              }
            }

            .custom_tab-content {
              .custom_tab-pane {
                .custom_nav-tabs {
                  margin-top: 0px;

                  .custom_nav-item {
                    .custom_nav-link {
                      font-size: 14px;
                      line-height: 15px;
                    }
                  }
                }

                .inner__tab-content {
                  .inner__tab-pane {
                    .global__content {
                      .visual__simulator__options {
                        height: 180px;

                        li {
                          margin-bottom: 10px;

                          a {
                            .card {
                              .card-img-top {
                                height: 100px;
                                padding: 0px;
                              }

                              .desc {
                                margin-top: 2px;
                                margin-bottom: 2px;

                                h6 {
                                  font-size: 12px;
                                  line-height: 14px;
                                  margin-bottom: 0px;
                                  font-weight: 600;
                                }

                                p {
                                  font-size: 10px;
                                  line-height: 14px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .product_info_modal {
    .custom_simulator_modal-dialog {
      margin: 1rem auto;

      .custom_modal-content {
        .custom_modal-header {
          .custom_modal-title {
            font-size: 16px;
            line-height: 22px;
            padding: 10px 0px;
          }

          .custom__close {
            top: 5px;
          }
        }

        .custom_modal-body {
          padding-top: 0px;
          padding-bottom: 0px;

          .product__selection__part {
            .custom_nav-tabs {
              .custom_nav-item {
                .custom_nav-link {
                  font-size: 12px;
                  line-height: 24px;
                  padding-top: 0px;
                  padding-bottom: 0px;
                }
              }
            }

            .custom__card {
              .card-img-top {
                padding: 0px 25px;
                height: 170px;
              }

              .custom_card-body {
                padding: 5px 25px;

                p {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .global__simulator_colors_modal {
    .modal-lg {
      max-width: 600px;
    }
  }
}

@media (max-width: 750px) and (max-height: 430px) {
  .global__simulator_room_modal {
    .custom_simulator_modal-dialog {
      max-width: 675px;

      .custom_modal-content {
        .custom_modal-header {
          .custom_modal-title {
            font-size: 16px;
            line-height: 22px;
            padding: 10px 0px;
          }

          .custom__close {
            top: 5px;
          }
        }

        .custom_modal-body {
          padding-top: 10px;

          .product__selection__part {
            .custom__nav-pills {
              .custom_nav-item {
                .custom_nav-link {
                  font-size: 14px;
                  line-height: 15px;
                }
              }
            }

            .custom_tab-content {
              .custom_tab-pane {
                max-width: 630px;

                .custom_nav-tabs {
                  margin-top: 0px;

                  .custom_nav-item {
                    .custom_nav-link {
                      font-size: 14px;
                      line-height: 15px;
                    }
                  }
                }

                .inner__tab-content {
                  .inner__tab-pane {
                    max-width: 100%;

                    .global__content {
                      .visual__simulator__options {
                        height: 215px;

                        li {
                          margin-bottom: 10px;

                          a {
                            .card {
                              .card-img-top {
                                height: 100px;
                                padding: 0px;
                              }

                              .desc {
                                margin-top: 2px;
                                margin-bottom: 2px;

                                h6 {
                                  font-size: 12px;
                                  line-height: 14px;
                                  margin-bottom: 0px;
                                  font-weight: 600;
                                }

                                p {
                                  font-size: 10px;
                                  line-height: 14px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) and (max-height: 360px) {
  .global__simulator_modal {
    .custom_simulator_modal-dialog {
      margin: 1rem auto;
    }
  }

  .product_info_modal {
    .custom_simulator_modal-dialog {
      margin: 1rem auto;
    }
  }
}

/////////Home Page////////
.our_product_simulator_section {
  padding: 100px 0px;

  .top_desc {
    margin-bottom: 40px;

    h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 29px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: $secondaryColor;
      margin-bottom: 20px;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: rgba(51, 51, 51, 0.54);
    }
  }
}

.our_product_selection {
  position: relative;
  text-decoration: none;
  display: block;

  .custom__card {
    border: none;

    .card-img-top {
      width: 100%;
    }

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      text-transform: uppercase;
      color: $secondaryColor;
      background: rgba(255, 255, 255, 0.7);
      padding: 45px 55px;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .our_product_selection {
    .custom__card {
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
        color: $secondaryColor;
        background: rgba(255, 255, 255, 0.7);
        padding: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .our_product_simulator_section {
    .top_desc {
      padding: 0px 70px;
      margin-bottom: 30px;

      h2 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 12px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .our_product_selection {
    .custom__card {
      p {
        padding: 45px 45px;
      }
    }
  }
}

@media (max-width: 605px) {
  .our_product_simulator_section {
    .top_desc {
      padding: 0px 15px;
    }
  }
}

.simulator_selection {
  position: relative;
  text-decoration: none;
  display: block;

  .custom__card {
    border: none;

    .card-img-top {
      width: 100%;
    }

    .center_text_section {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 70px 100px;
      width: 80%;
      text-align: center;

      .t__text {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 40px;
        text-transform: uppercase;
        color: #ffffff;

        span {
          font-weight: normal;
          font-size: 24px;
          line-height: 26px;
        }
      }

      .b__text {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 40px;
        text-transform: uppercase;
        color: #ffffff;

        span {
          color: #ffffff;
          font-size: 24px;
          font-weight: normal;
        }
      }

      .get__start__btn {
        border: 1px solid #ffffff;
        background: #ffffff;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
        border-radius: 0px;
        padding: 12px 21px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .simulator_selection {
    .custom__card {
      .center_text_section {
        padding: 70px 25px;
      }
    }
  }
}

@media (max-width: 991px) {
  .simulator_selection {
    .custom__card {
      .center_text_section {
        padding: 30px 0px;
        width: 85%;

        .t__text {
          font-size: 26px;
          line-height: 24px;

          span {
            font-size: 18px;
            line-height: 22px;
          }
        }

        .b__text {
          font-size: 26px;
          line-height: 24px;
        }

        .get__start__btn {
          font-size: 12px;
          line-height: 16px;
          padding: 8px 16px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .simulator_selection {
    .custom__card {
      .center_text_section {
        padding: 30px 0px;
        width: 85%;

        .t__text {
          font-size: 20px;
          line-height: 22px;

          span {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .b__text {
          font-size: 20px;
          line-height: 22px;
        }

        .get__start__btn {
          font-size: 12px;
          line-height: 16px;
          padding: 8px 16px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .our_product_simulator_section {
    .custom___container__img {
      .custom___row__img {
        .custom___col__img {
          padding-left: 0px;
          padding-right: 0px;

          .custom___exploreRoom__img {
            height: 400px;
          }
        }
      }
    }
  }

  .simulator_selection {
    .custom__card {
      .center_text_section {
        padding: 80px 0px;
        width: 85%;

        .t__text {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;

          span {
            font-size: 10px;
            line-height: 14px;
          }
        }

        .b__text {
          font-size: 16px;
          line-height: 20px;
        }

        .get__start__btn {
          font-size: 12px;
          line-height: 16px;
          padding: 8px 16px;
        }
      }
    }
  }
}

/////////Home Page////////

///////Footer Css//////
.footer_section {
  // background: url("../images/footer_bg.png");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  .footer__options {
    background: $secondaryColor;
    padding-top: 80px;

    h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      align-items: center;
      color: #ffffff;
      margin-bottom: 25px;
    }

    .intro_sec {
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
        margin-top: 30px;
        margin-bottom: 0px;
      }
    }

    .global_footer_sec {
      .mob_social__icons {
        display: none;
      }

      a {
        display: block;
        text-decoration: none;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        // text-transform: capitalize;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 12px;

        .social__logo {
          margin-right: 15px;
        }

        .f_tag {
          font-weight: 600;
        }

        .address_section_footer {
          display: flex;

          .address_txt {
            margin-left: 5px;
          }
        }
      }
    }

    .bottom__section__footer {
      border-top: 1px solid #4c5056;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 25px 0px;

      .copy_right {
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
        color: #ffffff;

        span {
          font-weight: 600;
        }
      }

      .term_policy_sec_footer {
        a {
          text-decoration: none;
          font-weight: normal;
          font-size: 16px;
          line-height: 15px;
          color: rgba(255, 255, 255, 0.8);
        }

        a:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .footer_section {
    .footer__options {
      padding-top: 40px;

      .intro_sec {
        p {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }

      .global_footer_sec {
        .mob_social__icons {
          display: block;

          a {
            display: inline;
          }
        }

        .desk__view_social {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .footer_section {
    // background: url("../images/mob_footer_bg.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    .footer__options {
      padding-top: 40px;

      .bottom__section__footer {
        border-top: none;

        .term_policy_sec_footer {
          display: none;
        }
      }
    }
  }
}

/////Search Modal/////
.search_modal {
  .custom_modal-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width: none !important;

    .custom_modal-content {
      background: #000000c7;
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;

      .custom_modal-header {
        border-bottom: none;

        .close {
          color: white;
        }
      }

      .custom_modal-body {
        padding: 0px;
        // position: relative !important;

        .custom_input-group {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          padding: 0px 330px;

          .form-control {
            background: transparent;
            border: none;
            border-bottom: 1px solid white;
            border-radius: 0px;
            color: white;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;

            &:focus {
              box-shadow: none;
            }

            &::-webkit-input-placeholder {
              /* Edge */
              font-weight: normal;
              font-size: 18px;
              line-height: 30px;
              color: #ffffff;
            }

            &::-ms-input-placeholder {
              /* Internet Explorer */
              font-weight: normal;
              font-size: 18px;
              line-height: 30px;
              color: #ffffff;
            }

            &::placeholder {
              font-weight: normal;
              font-size: 18px;
              line-height: 30px;
              color: #ffffff;
            }
          }

          .input-group-append {
            .input-group-text {
              background: transparent;
              border: none;
              border-bottom: 1px solid white;
              border-radius: 0px;
            }
          }
        }
        // .custom-search__suggesion {
        //   max-height: 500px;
        //   display: flex;
        //   flex-direction: column;
        //   flex-flow: 1 1 auto;
        //   .box:not(:last-child) {
        //     border-bottom: 3px solid rgb(160, 9, 9);
        //   }
        // }

        .search-suggestions {
          position: absolute;
          top: 60%;
          left: 50%;
          -webkit-transform: translate(-50%, -15%);
          transform: translate(-50%, -15%);
          min-height: 0px;
          max-height: 300px;
          max-width: 300px;
          min-width: 250px;
          z-index: 1000;
          background-color: #ffffff;
          overflow-y: scroll;
          .items {
            padding: 15px 10px;

            a:hover {
              color: #ca9e67;
            }
          }
          .items:not(:last-child) {
            border-bottom: 1px solid #666;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .search_modal .custom_modal-dialog .custom_modal-content .custom_modal-body .search-suggestions {
    max-height: 250px;
    max-width: 700px;
    min-width: 550px;
  }
}

@media (min-width: 1441px) {
  .search_modal .custom_modal-dialog .custom_modal-content .custom_modal-body .search-suggestions {
    max-height: 350px;
    min-width: 850px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .search_modal {
    .custom_modal-dialog {
      .custom_modal-content {
        .custom_modal-body {
          .custom_input-group {
            padding: 0px 175px;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 650px) {
  .search_modal {
    .custom_modal-dialog {
      .custom_modal-content {
        .custom_modal-body {
          .custom_input-group {
            padding: 0px 120px;
          }
        }
      }
    }
  }
}

@media (max-width: 649px) and (min-width: 350px) {
  .search_modal {
    .custom_modal-dialog {
      .custom_modal-content {
        .custom_modal-body {
          .custom_input-group {
            padding: 0px 30px;
          }
        }
      }
    }
  }
}

/////Search Result Css/////
.search_result_number {
  background: #f7f9fb;
  padding: 25px 0px;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: $secondaryColor;
    margin-bottom: 8px;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $primaryColor;
    margin-bottom: 0px;
  }
}

.search_result_main {
  margin-top: 50px;

  .custom_input-group {
    .custom_form-control {
      border-right: none;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      padding-bottom: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      color: $secondaryColor;
      padding-left: 20px;
      border: 1px solid #d2d6dd;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        color: $secondaryColor;
      }
    }

    .form-control:not(textarea) {
      height: calc(1.5em + 1rem + 10px);
    }

    .input-group-prepend {
      .input-group-text {
        border-left: none;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        background: transparent;
      }
    }
  }

  .search_available_options {
    margin-top: 20px;
    text-align: center;

    ul {
      li {
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        margin-top: 10px;

        a {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
          text-transform: capitalize;
          color: $secondaryColor;
          text-decoration: none;
          display: block;
          padding: 8px 35px;
        }
      }
    }
  }

  .search_available_products {
    margin-top: 50px;

    ul {
      .media {
        .media-body {
          h5 {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 50px;
            align-items: center;
            text-transform: capitalize;
            color: rgba(51, 51, 51, 0.87);
          }

          p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: #333333;
            margin-bottom: 0px;
          }
        }
      }

      .media:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    .pagination__part {
      margin-top: 50px;
      margin-bottom: 80px;

      .pagination {
        .pagination__icons {
          .page-link {
            padding: 9px 15px 10px 15px;
            border-radius: 30px;

            &:hover {
              background: transparent;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        li {
          .page__number {
            padding: 9px 17px 10px 17px;
            border-radius: 30px;
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            align-items: center;
            text-align: center;
            color: $secondaryColor;

            &:hover {
              background: transparent;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .page__number.active {
            background: $primaryColor;
            color: $minBannerColor;
          }
        }

        li:not(:last-child) {
          margin-right: 30px;
        }
      }
    }

    .loadmore__btn__part {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 40px;

      .loadmore__btn {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        align-items: center;
        text-align: center;
        color: #ffffff;
        background: $secondaryColor;
        border: 1px solid $secondaryColor;
        padding: 7px 50px 8px 50px;
        border-radius: 0px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .page__notfound__part {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 100px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 41px;
      margin-top: 40px;
      margin-bottom: 0px;
      align-items: center;
      text-align: center;
      color: $secondaryColor;
    }
  }
}

@media (min-width: 768px) {
  .loadmore__btn__part {
    display: none;
  }
}

@media (max-width: 767px) {
  .pagination__part {
    display: none;
  }

  .search_result_main {
    .search_available_products {
      ul {
        .media {
          .media-body {
            h5 {
              font-size: 16px;
              line-height: 28px;
            }

            p {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 471px) {
  .search_result_main {
    margin-top: 40px;

    .custom_input-group {
      .custom_form-control {
        font-size: 18px;

        &::placeholder {
          font-size: 18px;
        }
      }

      .form-control:not(textarea) {
        height: calc(1.5em + 1rem + 5px);
      }
    }

    .search_available_options {
      margin-top: 10px;
      text-align: left;

      ul {
        li {
          margin-top: 10px;

          a {
            font-size: 14px;
            line-height: 16px;
            padding: 8px 25px;
          }
        }
      }
    }

    .search_available_products {
      margin-top: 30px;

      ul {
        .media {
          .media-body {
            h5 {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 5px;
            }

            p {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        .media:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      .loadmore__btn__part {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;

        .loadmore__btn {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 30px;
          align-items: center;
          text-align: center;
          color: #ffffff;
          background: $secondaryColor;
          border: 1px solid $secondaryColor;
          padding: 7px 50px 8px 50px;
          border-radius: 0px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .page__notfound__part {
      margin-top: 50px;
      margin-bottom: 90px;

      img {
        width: 90%;
      }

      p {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

///////Mobile Side Nav Drawer /////

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #f3f3f3;
  overflow-x: hidden;
  transition: 0.5s;

  .navDrawer__top_part {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;

    .right__sec {
      .search__icon {
        margin-right: 10px;
      }
    }
  }

  .drawer_menu__section {
    border-top: 1px solid $primaryColor;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 49px;
      align-items: center;
      text-align: center;
      color: $primaryColor;
      margin-bottom: 10px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: $secondaryColor;
      padding: 0px 150px;
    }

    .collapse-questions-section {
      padding-top: 30px;

      .css-collapse__content {
        .tab:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        .tab {
          position: relative;
          margin-bottom: 1px;
          width: 100%;
          color: rgba(0, 0, 0, 0.87);
          padding: 25px 0px;
          padding-bottom: 0px;
          // box-sizing: border-box;
          // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
          // border-radius: 3px;

          &:last-child input:checked ~ label {
            border-bottom: none;
          }

          input {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          label {
            font-size: 18px;
            margin: 0;
            line-height: 25px;
            font-weight: normal;
            color: #2a3c50;
            position: relative;
            display: block;
            padding: 10px;
            cursor: pointer;
            transition: 0.15s;
          }

          .tab-content {
            max-height: 0;
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;
            letter-spacing: 0.216667px;
            padding-left: 40px;
            padding-right: 20px;
            margin-top: 20px;
            overflow: hidden;
            -webkit-transition: max-height 0.05s;
            -o-transition: max-height 0.05s;
            transition: max-height 0.05s;
            color: rgba(42, 60, 80, 0.8);
            background: transparent;

            .submenu__options {
              .sub-title {
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                align-items: center;
                color: $secondaryColor;
                margin-bottom: 15px;
              }

              .list-unstyled {
                li {
                  a {
                    text-decoration: none;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 19px;
                    align-items: center;
                    color: $secondaryColor;
                  }

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                }
              }

              &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              }

              &:not(:first-child) {
                margin-top: 15px;
              }
            }
          }
        }

        .tab input:checked ~ .tab-content {
          max-height: 100%;
          margin-bottom: 15px;
        }

        .tab label::before {
          position: absolute;
          right: 15px;
          top: 8px;
          display: block;
          text-align: center;
        }

        .tab input[type="checkbox"] + label::before {
          content: url("/images/down_arrow.svg");
          font-size: 25px;
          color: #2a3c50;
        }

        .tab input[type="checkbox"]:checked + label::before {
          //transform: rotate(315deg);
          content: url("/images/up_arrow.svg");
          font-size: 35px;
          color: #2a3c50;
          top: 6px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .sidenav .navDrawer__top_part {
    padding: 10px 15px;
  }
}

// .sidenav a {
//   padding: 8px 8px 8px 32px;
//   text-decoration: none;
//   font-size: 25px;
//   color: #818181;
//   display: block;
//   transition: 0.3s;
// }

// .sidenav a:hover {
//   color: #f1f1f1;
// }

// .sidenav .closebtn {
//   position: absolute;
//   top: 0;
//   right: 25px;
//   font-size: 36px;
//   margin-left: 50px;
// }

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

///////Carousal Part Css/////
.top__carousal {
  .slide__part {
    // background-image: url("/images/slick.png");
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    height: 600px;
    position: relative;

    .inner__part {
      display: block;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);

      .center__content {
        position: absolute;
        z-index: 100;
        text-align: center;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        h3 {
          font-weight: 600;
          font-size: 48px;
          line-height: 65px;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
        }

        p {
          font-weight: normal;
          font-size: 20px;
          line-height: 36px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
        }

        .slider__btn {
          border: 1px solid white;
          background: white;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #333333;
          text-decoration: none;
          border-radius: 0px;
          padding: 11px 30px 12px 30px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .slick-prev {
    left: 25px;
    z-index: 1;
    width: auto;
  }

  .slick-prev:before {
    content: url("/images/slick_left_arrow.svg") " Prev";
    opacity: 1;
    font-size: 18px;
    line-height: 29px;
  }

  .slick-next {
    right: 25px;
    z-index: 1;
    width: auto;
  }

  .slick-next:before {
    content: "Next " url("/images/slick_right_arrow.svg");
    opacity: 1;
    font-size: 18px;
    line-height: 29px;
  }
}

@media (max-width: 991px) {
  .top__carousal {
    .slide__part {
      .inner__part {
        .center__content {
          h3 {
            font-size: 36px;
            line-height: 45px;
          }

          p {
            font-size: 16px;
            line-height: 28px;
          }

          .slider__btn {
            font-size: 14px;
            line-height: 18px;
            padding: 9px 25px 10px 25px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .top__carousal {
    .slide__part {
      .inner__part {
        .center__content {
          h3 {
            font-size: 28px;
            line-height: 38px;
          }

          p {
            font-size: 13px;
            line-height: 26px;
          }

          .slider__btn {
            font-size: 12px;
            line-height: 16px;
            padding: 7px 25px 8px 25px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .top__carousal {
    .slick-prev {
      top: unset;
      bottom: 20px;
      left: 15px;
    }

    .slick-next {
      top: unset;
      bottom: 20px;
      right: 15px;
    }
  }
}

@media (max-width: 450px) {
  .top__carousal {
    .slide__part {
      .inner__part {
        .center__content {
          h3 {
            font-size: 22px;
            line-height: 30px;
          }

          p {
            font-size: 12px;
            line-height: 22px;
          }

          .slider__btn {
            font-size: 12px;
            line-height: 16px;
            padding: 7px 25px 8px 25px;
          }
        }
      }
    }
  }
}

/////Our Services/////
.our_service_section {
  padding: 100px 0px;
  background: #f7f9fb;

  .top_desc {
    margin-bottom: 40px;

    h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 29px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: $secondaryColor;
      margin-bottom: 20px;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: rgba(51, 51, 51, 0.54);
    }
  }

  .service__part {
    text-align: center;

    img {
      height: 61px;
    }

    h4 {
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      text-transform: capitalize;
      color: $secondaryColor;
      margin-top: 45px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      text-transform: lowercase;
      color: #6e6e6e;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .our_service_section {
    .service__part {
      h4 {
        font-size: 14px;
        line-height: 24px;
        margin-top: 25px;
      }

      p {
        font-size: 11px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .our_service_section {
    padding: 60px 0px;

    .top_desc {
      h2 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 12px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .service__part {
      h4 {
        margin-top: 15px;
        margin-bottom: 30px;
      }
    }
  }
}

a {
  color: #333333;
  text-decoration: none;

  &:hover {
    color: #ab7f5b;
    text-decoration: none;
  }
}

//

.art-preloader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5;
  z-index: 999999;
}

.art-preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.art-preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #ca9e67;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.art-preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.oneFourth {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 1200px) {
  .mobile-map {
    height: 80vh;
    width: 95vw;
  }
}

.mobile-list-unstyled {
  // li {
  //   padding: 10px 0px;

  //   a {
  //     font-weight: normal;
  //     font-size: 20px;
  //     line-height: 27px;
  //     align-items: center;
  //     color: $secondaryColor;
  //     text-decoration: none;
  //   }
  // }
  // ul{
  //   padding: 10px 0px;
  // }

  ul:not(:last-child) {
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.mb_40 {
  margin-bottom: 40px;
}
