// TurnKey Solutions
.turnKey__solutions {
  .top__title {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: 577px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    h3 {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      color: #333333;
      margin-bottom: 0;
    }
    p {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 29px;
      text-align: center;
      color: #333333;
      margin-bottom: 0;
    }
    a {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #ca9e67;
    }
  }
  .pack__lists {
    h3 {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      color: #333333;
      @media (max-width: 577px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;
      li {
        flex-basis: 33.33%;
        max-width: 33.33%;
        margin-bottom: 5px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;

        color: #333333;
        position: relative;
        padding-left: 30px;
        @media (max-width: 577px) {
          font-size: 16px;
          line-height: 22px;
        }
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: #ca9e67;
          left: 0;
          top: 10px;
        }
        @media (max-width: 577px) {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
  }
  .other__lists {
    li {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
  }
  .call__us {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    @media (max-width: 577px) {
      margin-bottom: 15px;
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        img {
          transform: scale(1);
        }
        span {
          color: #ca9e67;
          text-decoration: underline;
        }
      }
    }
    img {
      transform: scale(0.8);
      transition: all 0.3s ease-in-out;
    }
    span {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: #333333;
      margin-left: 10px;
      transition: all 0.3s ease-in-out;
      @media (max-width: 577px) {
        font-size: 18px;
      }
    }
  }
}
.normal__img__card {
  margin-bottom: 20px;
  @media (max-width: 577px) {
    margin-bottom: 10px;
  }
  .img {
    img {
      width: 100%;
    }
  }
  .info {
    padding: 15px 0;
    h6 {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
      @media (max-width: 577px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
