// Fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,600;1,300;1,600&display=swap");
// import
@import "styleComponentTwo";
@import "styleTwo";

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

ul {
  list-style: none;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}
