.distric_list_section {
  margin-top: 40px;
  @include responsive-size(mobile) {
    margin-top: 20px;
  }
}
.dist_list {
  margin-bottom: 20px;
  h6 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
    @include responsive-size(mobile) {
      font-size: 16px;
    }
  }
  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    @include responsive-size(mobile) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
