// Component Variables

// Responsive Mixin
@mixin responsive-size($size) {
  @if $size == Ldevice {
    @media (max-width: 1199px) {
      @content;
    }
  }
  @if $size == Ltab {
    @media (max-width: 992px) {
      @content;
    }
  }
  @if $size == tab {
    @media (max-width: 769px) {
      @content;
    }
  }
  @if $size == mobile {
    @media (max-width: 577px) {
      @content;
    }
  }
}

// Global Classes
.leftEl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.centerEl {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightEl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// Banner Breadcumb
.text__banner {
  height: 120px;
  background: #f7f9fb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .page__name {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #333333;
    text-align: center;
  }
}

// Page Breadcumb
.page__breadcumb {
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    position: relative;
    &::after {
      content: "/";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #333333;
    }
    a {
      padding: 0 10px;

      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
    }
    &:first-child a {
      padding-left: 0;
    }

    &:last-child::after {
      display: none;
    }
  }
  .active {
    a {
      color: #ca9e67;
      padding-right: 0;
    }
  }
}

// ODD Even Card
.oddeven__card {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  @include responsive-size(tab) {
    flex-wrap: wrap;
  }
  .img__container {
    height: 350px;
    flex-basis: 50%;
    max-width: 50%;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    @include responsive-size(tab) {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  .info__container {
    flex-basis: 50%;
    max-width: 50%;
    @include responsive-size(tab) {
      flex-basis: 100%;
      max-width: 100%;
    }
    .date {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 15px;
    }
    .title {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 49px;
      display: flex;
      align-items: center;
      color: #ca9e67;
      margin-bottom: 20px;
    }
    .desc {
      font-family: Nunito;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      color: #333333;
      margin-bottom: 15px;
    }
    .read__more {
      background: #ca9e67;
      width: 150px;
      padding: 10px;

      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.odd__card {
  .img__container {
    order: 1;
  }
  .info__container {
    order: 2;
    padding-left: 70px;
  }
}
.even__card {
  .img__container {
    order: 2;
    @include responsive-size(tab) {
      order: 1;
    }
  }
  .info__container {
    order: 1;
    padding-right: 70px;
    @include responsive-size(tab) {
      order: 2;
    }
  }
}

// Simple Card
.simple__card {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
  &:hover {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    .card__link {
      text-decoration: none;
      .card__img img {
        transform: scale(1.15);
      }
      .bottominfo .title {
        padding-left: 15px;
      }
    }
    .innerinfo .title::after {
      width: 90px;
      height: 3px;
    }
  }
  .card__img {
    img {
      transition: all 2s ease-in;
      width: 100%;
    }
  }
  // Center Info
  .centerinfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 260px;
    min-height: 120px;
    // margin: 0 auto;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive-size(tab) {
      width: 200px;
    }
    @include responsive-size(mobile) {
      width: 260px;
    }
    .title {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      text-transform: uppercase;
      color: #333333;
      text-align: center;
      margin-bottom: 0;
    }
  }

  // Inner Info
  .innerinfo {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(51, 51, 51, 0.9) 100%
    );

    padding: 40px 30px 20px;

    .title {
      font-size: 30px;
      line-height: 42px;
      color: #ffffff;

      margin-bottom: 5px;
      position: relative;
      transition: all 2s ease-in;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        left: 0;
        bottom: 0;
        height: 3px;
        background-color: #ca9e67;
        transition: all 1.5s ease-in;
      }
    }
    .subtitle {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }

  // Bottom Info
  .bottominfo {
    padding: 10px 0;
    position: relative;
    width: 100%;
    background-color: #ffffff;

    .title {
      transition: all 0.5s ease-in;
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      color: rgba(51, 51, 51, 0.87);
      margin-bottom: 0;
      text-decoration: none;
    }
  }
}

// Zoom Overlay card
.zoom__card {
  position: relative;
  .zoom__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in;
    .iiz__btn {
      display: none;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.5);
      .overlay__icon {
        display: block;
        position: relative;
        z-index: 111;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }

  .card__img {
    img {
      width: 100%;
    }
  }
}

// Page Pagination
$paginationBGColor: #ca9e67;
$paginationColor: #ffffff;
$paginationBorderColor: #ca9e67;
$defaultColor: #333333;
$paginationFS: 16px;
$paginationLH: 22px;
.page__pagination {
  @include responsive-size(mobile) {
    display: none;
  }
  .pagination__items {
    display: flex;
    align-items: center;
    .page__number {
      width: 40px;
      height: 40px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .page__link {
        text-align: center;
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $defaultColor;
        font-size: $paginationFS;
        line-height: $paginationLH;
        transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        border-style: none;
        background: none;
        &:hover {
          text-decoration: none;
          background-color: rgba($color: $paginationBGColor, $alpha: 0.2);
          border-radius: 50%;
        }
      }
    }
    .active__number {
      background-color: $paginationBGColor;
      border-radius: 50%;
      .page__link {
        color: $paginationColor;
      }
    }
    .next__page,
    .prev__page {
      border-radius: 50%;
      border: 1px solid $paginationBorderColor;
      transition: all 0.1s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      margin: 0 15px;
      &:hover {
        background-color: $paginationBGColor;
        color: $paginationColor;
        border: none;
        svg path {
          stroke: $paginationColor;
        }
      }
    }
  }
}

// Load More BTN For Mobile
.load__moreEL {
  display: none;
  margin-bottom: 30px;
  @include responsive-size(mobile) {
    display: flex;
  }
  .load__moreBTN {
    background: #413120;
    width: 200px;
    height: 45px;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
  }
}

// Name inputs
.finput_name,
.finput_w_label,
.fselect_default,
.ffile_upload,
.input-group,
.fselect_innerlabel,
.fg-checkbox,
.ftextarea {
  margin-bottom: 20px;
  .form-control {
    //border: 1px solid rgba(0, 0, 0, 0.4);
    border: none;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 0;

    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: rgba(51, 51, 51, 0.5);
    padding-left: 0;

    height: 40px;
  }
}

// Check BOX
.fcheckbox {
  margin-bottom: 25px;
  .custom-control-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
    color: rgba(51, 51, 51, 0.7);
    display: block;
    width: 100%;
    cursor: pointer;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ca9e67;
    background-color: #ca9e67;
  }
}

// Form Radio
.fradio {
  margin-bottom: 10px;
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ca9e67;
    background-color: #ca9e67;
  }
}

// Text Area
.ftextarea {
  textarea {
    min-height: 150px;
  }
}

// Submit CTA
.submit__cta {
  .submit__btn {
    background: transparent;
    outline: none;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ca9e67;
    cursor: pointer;

    border: 1px solid #ca9e67;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin: 15px 0;
  }
}

// Basic
.basic__iconCard {
  padding: 40px 25px;
  background-color: #f7f9fb;

  text-align: center;

  margin-bottom: 30px;

  .basic__icon {
    margin-bottom: 25px;
  }
  .basic__title h4 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 15px;
  }
  .basic__desc p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #282828;
    margin-bottom: 25px;
  }
  .basic__cta {
    .explore__more {
      width: 170px;
      padding: 10px;
      border: 1px solid #333333;

      display: inline-block;
      text-align: center;

      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

// Basic Media Card
.primary_color {
  color: #ca9e67;
}
.basic__media {
  display: flex;
  align-items: flex-start;

  margin-bottom: 50px;
  @include responsive-size(mobile) {
    flex-wrap: wrap;
  }
  &--icon {
    //margin-right: 60px;
    flex-basis: 25%;
    max-width: 25%;
    @include responsive-size(tab) {
      flex-basis: 20%;
      max-width: 20%;
      text-align: center;
    }
    @include responsive-size(mobile) {
      flex-basis: 100%;
      max-width: 100%;
      text-align: center;
    }
    img {
      width: 80px;
      @include responsive-size(mobile) {
        width: 60px;
        margin-bottom: 20px;
      }
    }
  }
  &--content {
    flex-basis: 75%;
    max-width: 75%;
    @include responsive-size(mobile) {
      flex-basis: 100%;
      max-width: 100%;
      text-align: center;
    }
    .title {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #413120;
      margin-bottom: 10px;
    }
    .desc {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #333333;
    }
  }
}

// CSS Collapse
.page__sidebar {
  padding: 35px 30px;
  background: #f7f9fb;
  @include responsive-size(tab) {
    margin-bottom: 20px;
  }
  .sidebar__title {
    margin-bottom: 25px;
    h4 {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #ca9e67;
    }
  }
  .sidebar__innerpagelink {
    ul {
      li {
        margin-bottom: 15px;
        padding-left: 15px;
        a {
          font-family: Nunito;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 22px;
          text-transform: capitalize;
          color: rgba(51, 51, 51, 0.67);
          text-decoration: none;
          &:hover {
            color: #ca9e67;
          }
        }
      }
      li.active a {
        color: #ca9e67;
        font-weight: bold;
      }
    }
  }

  .sidebar__pagelink {
    ul {
      li {
        border-bottom: 1px solid rgba(51, 51, 51, 0.25);
        a {
          font-family: Nunito;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          color: rgba(51, 51, 51, 0.87);
          padding: 20px 0;
          display: inline-block;
          width: 100%;
          text-decoration: none;
        }
      }
      li.active a {
        color: #ca9e67 !important;
      }
    }
  }
}
// CSS Collapse
.css__collapse {
  .tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);

    &:last-child .tab__label {
      border-bottom: 1px solid rgba(51, 51, 51, 0.25);
    }

    &:last-child .tab__input:checked ~ .tab__label {
      border-bottom: none;
    }

    &:last-child .tab__input:checked ~ .tab-content {
      border-bottom: 1px solid rgba(51, 51, 51, 0.25);
    }

    &:last-child .tab__input:checked ~ .tab-content p {
      margin-bottom: 15px;
    }

    .tab__input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    .tab__label {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: rgba(51, 51, 51, 0.87);

      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      border-top: 1px solid rgba(51, 51, 51, 0.25);
      // transition: 0.15s;

      padding: 20px 0;
      margin-bottom: 0;
    }

    .tab-content {
      max-height: 0;
      overflow: hidden;

      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;

      transition: max-height 0.05s;
      color: rgba(0, 0, 0, 0.87);

      padding-left: 3px;
    }
  }

  .tab .tab__input:checked ~ .tab-content {
    max-height: 100%;
    //margin-bottom: 15px;
  }

  .tab .tab__label::before {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    text-align: center;
    //transition: all 0.35s ease-in;
  }

  .tab .tab__input[type="checkbox"] + .tab__label::before {
    content: "+";
    font-size: 34px;
    font-family: Nunito;
  }

  .tab .tab__input[type="checkbox"]:checked + .tab__label::before {
    content: "-";
    font-size: 50px;
    font-family: Nunito;
    top: 45%;
    transform: translateY(-55%);
  }
}

.fcheckbox {
  .custom-control-input:checked ~ .custom-control-label {
    color: #ca9e67;
  }
}

// Page Tabs
.page__tabs {
  margin-bottom: 20px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive-size(Ltab) {
      justify-content: flex-start;
      overflow-y: scroll;
      padding-bottom: 15px;
    }
    li {
      margin: 0 5px;
      @include responsive-size(Ltab) {
        min-width: 180px;
        text-align: center;
      }
      a {
        padding: 10px;
        text-decoration: none;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #333333;
      }
    }
    li.active {
      a {
        position: relative;
        color: #ca9e67;
        &::before {
          position: absolute;
          content: "(";
          left: -2px;
          top: 45%;
          transform: translateY(-50%);
        }
        &::after {
          position: absolute;
          content: ")";
          right: -3px;
          top: 45%;
          transform: translateY(-50%);
        }
      }
    }
    li:first-child {
      @include responsive-size(Ltab) {
        min-width: 75px;
        margin-left: -12px;
      }
    }
  }
}

// Load More CTA
.load__more {
  margin-top: 20px;
  .load__moreCTA {
    width: 150px;
    background: #ca9e67;
    display: inline-block;
    padding: 10px;
    text-align: center;
    text-decoration: none;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .load__moreBTN {
    width: 150px;
    background: #ca9e67;
    display: inline-block;
    padding: 10px;
    text-align: center;
    text-decoration: none;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
    outline: none;
    border: none;
  }
}

// Media & News
.media_news {
  .oddeven__card {
    @include responsive-size(Ltab) {
      .info__container {
        padding-left: 30px;
        padding-right: 30px;
        .title {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
    @include responsive-size(tab) {
      .info__container {
        padding-left: 0;
        padding-right: 0;
        .title {
          font-size: 30px;
          line-height: 36px;
        }
      }
    }
  }
}

.prod-active {
  color: #ca9e67;
}

.mbl-banner {
  @include responsive-size(mobile) {
    padding-top: 5px;
    height: 150px;
  }
  .page__breadcumb {
    @include responsive-size(mobile) {
      flex-direction: column;
    }
  }
}
